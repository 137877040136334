import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Color, DoubleSide, Group, LoopOnce, TextureLoader } from "three";
import {
  useGLTF,
  useAnimations,
  Plane,
  Circle,
  Html,
  useProgress,
} from "@react-three/drei";
import { AllStar } from "../AllStar/AllStar";
import { useLoader } from "@react-three/fiber";
import { MODEL_BY_PLAYER_KEY, OPPONENTS, PLAYER_KEYS } from "../../App";

// const modelPath = "/team-stand-off.glb";
const stadiumModelPath = "/team-stand-off-animation.glb";
// const stadiumModelPath = "/team-stand-off-three-teams.glb";
const grassPath = "/Pitch-Faded.png";
// const modelPath = "/box.glb";

type Props = { opponentId: string };
const selectAnimationMap: { [key: string]: string } = {
  "team-2-player-1": "team-2-player-1Action",
  "team-2-player-2": "team-2-player-2Action",
  "team-2-player-3": "team-2-player-3Action",
  "team-2-player-4": "team-2-player-4Action",
  "team-3-player-1": "team-2-player-1Action",
  "team-3-player-2": "team-2-player-2Action",
  "team-3-player-3": "team-2-player-3Action",
  "team-3-player-4": "team-2-player-4Action",
};

export const Stadium = ({ opponentId }: Props) => {
  const groupRef = useRef<Group>(null);
  const { nodes, animations, scene } = useGLTF(stadiumModelPath);
  // const [currentOpponentId, setCurrentOpponentId] =
  //   useState<string>(opponentId);

  const grassTexture = useLoader(TextureLoader, grassPath);
  const { actions, mixer } = useAnimations(animations, scene);

  // useEffect(() => {
  //   const id = "team-2-player-1Action.001";
  //   actions[id]?.play();
  // }, [actions, nodes]);

  // useEffect(() => {
  //   // TODO: lift the animation state up to higher component
  //   // make the state follow the flow of the animation en keep updating the state accordingly
  //   // e.g. type PlayerState = { pos: Vec3d; id: string;  type: 'selected' } | { type: 'walking-off'; id: string; } | { type: 'deselected'; id: string } | { type: 'walking-on'; id: string}

  //   //  ::::::HACKY FOR PROTO:::::::
  //   // start currentOpponentId animation
  //   const opponent = OPPONENTS.find(({ id }) => id === currentOpponentId);

  //   const animations =
  //     opponent?.playerKeys.map((playerKey) => {
  //       const animationId = selectAnimationMap[playerKey];
  //       const walkOffAnimation = actions[animationId];
  //       return walkOffAnimation;
  //     }) || [];
  //   // listen to 1 animation to finish
  //   const walkOffAnimation = animations[3];
  //   const onLoopFinished = (event: any) => {
  //     if (walkOffAnimation) {
  //       if (event.action === walkOffAnimation) {
  //         //finshed, swap currentOpponentId to opponentId
  //         mixer.removeEventListener("loop", onLoopFinished);
  //         animations.forEach((animation) => {
  //           animation?.stop();
  //         });
  //         setCurrentOpponentId(opponentId);
  //       }
  //     }
  //   };
  //   mixer.addEventListener("loop", onLoopFinished);
  //   if (currentOpponentId !== opponentId) {
  //     animations.forEach((animation) => {
  //       animation?.play();
  //     });
  //   }
  //   return () => {
  //     mixer.removeEventListener("loop", onLoopFinished);
  //   };
  // }, [actions, currentOpponentId, mixer, opponentId]);

  const opponentData = useMemo(() => {
    return OPPONENTS.find(({ id }) => id === opponentId);
  }, [opponentId]);

  return (
    <>
      <group ref={groupRef} scale={5} position={[0, -5, 0]} dispose={null}>
        {/* full scene */}
        {/* <primitive object={nodes.Scene} /> */}
        <primitive object={nodes.Ball} material-color={"white"} scale={1} />
        {PLAYER_KEYS.map((key, index) => {
          const nodeFromBlenderScene = nodes[key];

          return (
            <AllStar
              key={key}
              follow={nodeFromBlenderScene}
              modelPath={MODEL_BY_PLAYER_KEY[key]}
              buttonPosition={[-1, 3.7, 0]}
              onSelect={
                index === 0
                  ? () => {
                      const animationId = selectAnimationMap[key] as any;
                      if (animationId) {
                        const thing = actions[animationId];
                        thing?.reset().setLoop(LoopOnce, 1).play();
                      }
                    }
                  : undefined
              }
            />
          );
        })}
        {opponentData?.playerKeys.map((key, index) => {
          const nodeFromBlenderScene = nodes[`team-2-player-${index + 1}`];
          const modelPath = MODEL_BY_PLAYER_KEY[key];
          // return null;
          return (
            <AllStar
              key={index}
              follow={nodeFromBlenderScene}
              modelPath={modelPath}
              buttonPosition={[-0.5, 2.3 + index * 0.21, 0]}
            />
          );
        })}{" "}
        <Circle
          position={[0, 0, 0]}
          args={[8, 60]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <meshStandardMaterial
            attach="material"
            map={grassTexture}
            transparent={true}
          />
        </Circle>
        <Circle receiveShadow args={[8, 60]} rotation={[-Math.PI / 2, 0, 0]}>
          <shadowMaterial attach="material" opacity={0.1} color="black" />
        </Circle>
      </group>
    </>
  );
};

useGLTF.preload(stadiumModelPath);
