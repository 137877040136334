import React, {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { Canvas, useFrame } from "@react-three/fiber";
import {
  DirectionalLight,
  DirectionalLightHelper,
  Group,
  MathUtils,
  SpotLight,
  SpotLightHelper,
  Vector2,
  Vector3,
} from "three";
//@ts-ignore
import { gsap } from "gsap/dist/gsap";
import {
  Environment,
  PerspectiveCamera,
  Html,
  OrbitControls,
  useHelper,
  Plane,
} from "@react-three/drei";
import { Stadium } from "../Stadium/Stadium";
import { useLocation } from "react-router-dom";

const startPosCamera = new Vector3(0, 5.151436771522862, 63.77854941000632);

function Rig({ children }: { children: any }) {
  const ref = useRef<any>();

  const mouse = useRef<Vector2>(new Vector2(0, 0));
  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    const width = window.innerWidth;
    const height = window.innerHeight;

    mouse.current.x = (event.clientX / width) * 2 - 1;
    mouse.current.y = -(event.clientY / height) * 2 + 1;
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  useFrame((state) => {
    if (!ref.current) return;
    ref.current.rotation.y = MathUtils.lerp(
      ref.current.rotation.y,
      mouse.current.x * Math.PI * -0.05,
      0.05
    );
    // ref.current.rotation.x = MathUtils.lerp(
    //   ref.current.rotation.x,
    //   mouse.current.y * Math.PI * 0.08,
    //   0.05
    // );
    ref.current.position.y = MathUtils.lerp(
      ref.current.position.y,
      mouse.current.x * 1,
      0.05
    );
  });
  return <group ref={ref}>{children}</group>;
}
type WorldProps = { currentOpponentId: string };
const World = ({ currentOpponentId }: WorldProps) => {
  const routeGroups = useRef<Group>(null);

  const location = useLocation();
  const directionalLightRef = useRef<DirectionalLight>(null!);
  useHelper(directionalLightRef, DirectionalLightHelper, 1, "red");

  const spotLightRef = useRef<SpotLight>(null!);
  // useHelper(spotLightRef, SpotLightHelper, "blue");

  useFrame(({ camera, clock }) => {
    if (!routeGroups.current) return;
    const scale = Math.min(1, window.innerWidth / window.innerHeight);
    routeGroups.current.scale.set(scale, scale, scale); // window.innerHeight;

    const ypos = location.pathname === "/" ? -90 : -3;

    routeGroups.current.position.y = MathUtils.lerp(
      routeGroups.current.position.y,
      ypos * scale,
      0.05
    );
  });
  return (
    <>
      <Environment preset="city" />
      <ambientLight intensity={0.1} />

      <spotLight
        castShadow
        receiveShadow
        intensity={0.49}
        ref={spotLightRef}
        position={[0, 17, 0]}
      />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 3}
        makeDefault={true}
        enabled={true}
      />
      <PerspectiveCamera makeDefault position={startPosCamera} />

      <group receiveShadow ref={routeGroups}>
        {/* HOME PAGE */}
        <group position={[0, 90, 0]}>
          <Rig>
            <Html position={[0, 0, -20]} zIndexRange={[-5, -1]}>
              <div className="ui-get-ready">
                <h1 className="cy-welcome-title ui-match-title">
                  Welcome
                  <br />
                  to
                  <br />
                  AIFA!
                </h1>
              </div>
            </Html>
          </Rig>
        </group>
        {/* TEAM SELECT PAGE */}
        <group position={[0, -13, 0]}>
          <Rig>
            <Stadium opponentId={currentOpponentId} />

            <Html position={[0, 13, -20]} zIndexRange={[-5, -1]}>
              <div className="ui-get-ready">
                <h1 className="cy-select-title ui-match-title">
                  Ready to
                  <br />
                  match up
                </h1>
              </div>
            </Html>
          </Rig>
        </group>
      </group>
    </>
  );
};
type ModelProps = {
  currentOpponentId: string;
};

export const ThreeD = ({ currentOpponentId }: ModelProps) => {
  return (
    // <Canvas camera={{ fov: 50 }} dpr={[1, 2]}>
    <Canvas shadows>
      <Suspense fallback={null}>
        <World currentOpponentId={currentOpponentId} />
      </Suspense>
    </Canvas>
  );
};
