import React, { createRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Site } from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import SelectTeam from "./routes/SelectTeam";

export const ROUTES = [
  { path: "/", name: "Home", element: <Home />, nodeRef: createRef() },
  {
    path: "/select-team",
    name: "About",
    element: <SelectTeam />,
    nodeRef: createRef(),
  },
];
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Site />,
//     children: ROUTES.map((route) => ({
//       index: route.path === "/",
//       path: route.path === "/" ? undefined : route.path,
//       element: route.element,
//     })),
//   },
// ]);
// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
