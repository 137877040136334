import { useNavigate } from "react-router-dom";
import { Sticky } from "../components/Sticky/Sticky";

type Props = {};
function Home() {
  const navigate = useNavigate();
  return (
    <div className="landing-page">
      <Sticky className="landing-page__btn">
        {/* <Link className="team-page-container-next" to={"/select-team"}>
          link to next page
        </Link> */}
        <button
        
          type="button"
          className="button"
          onClick={() => {
            navigate("../select-team");
          }}
        >
          Start a Match!
        </button>
      </Sticky>
    </div>
  );
}

export default Home;
