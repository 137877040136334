import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Group, Vector2 } from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";

import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { applyProps, useFrame, useGraph } from "@react-three/fiber";
import { Sticky } from "../Sticky/Sticky";
import { Instance } from "@react-three/fiber/dist/declarations/src/core/renderer";

// const modelPath = "/CryptoNaut03.glb";

const idleKey = "Legger_Original_Idle_Default";
const jumpKey = "Legger_Original_PowerUp_CryptoNaut";

type ModelProps = {
  //   animateJump: boolean;
  modelPath: string;
  onSelect?: () => void;
};

export function useSkinnedMeshClone(path: string) {
  const { scene, materials, animations } = useGLTF(path);
  const clonedScene = useMemo(() => clone(scene), [scene]);
  const { nodes } = useGraph(clonedScene);

  return { scene: clonedScene, materials, animations, nodes };
}
export const AllStarModel = ({ modelPath, onSelect }: ModelProps) => {
  const [animateJump, setAnimateJump] = useState<boolean>(false);

  const { nodes, animations } = useSkinnedMeshClone(modelPath);
  // const { nodes, materials, animations } =
  //   useSkinnedMeshClone("/OddFrog_Mesh.glb");

  const allStarRef = React.useRef<Group>(null);
  const mouse = React.useRef<Vector2>(new Vector2(0, 0));

  const { actions, mixer } = useAnimations(animations, allStarRef);

  useEffect(() => {
    actions[idleKey]?.play();
    // actions[jumpKey]?.play();
  }, [actions]);

  useEffect(() => {
    const onLoopFinished = (event: any) => {
      // TODO: check https://codesandbox.io/s/pecl6 for fadeIn/Out of animations
      const startAction = animateJump ? actions[idleKey] : actions[jumpKey];
      const endAction = animateJump ? actions[jumpKey] : actions[idleKey];
      if (startAction && endAction) {
        if (event.action === startAction) {
          mixer.removeEventListener("loop", onLoopFinished);
          startAction.stop();
          endAction.play();
        }
      }
    };
    mixer.addEventListener("loop", onLoopFinished);

    return () => {
      mixer.removeEventListener("loop", onLoopFinished);
    };
  }, [actions, animateJump, mixer]);

  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    const width = window.innerWidth;
    const height = window.innerHeight;

    mouse.current.x = (event.clientX / width) * 2 - 1;
    mouse.current.y = -(event.clientY / height) * 2 + 1;
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useLayoutEffect(() => {
    // enable the shadows, they might not be present in the blender export?
    nodes.Scene.traverse((obj) => {
      // HACKERDYHACK
      const o = obj as unknown as Instance;
      if (o.isMesh) {
        applyProps(o, {
          castShadow: true,
          receiveShadow: true,
          "material-envMapIntensity": 0.2,
        });
      }
    });
  }, []);

  return (
    <group>
      {onSelect && (
        <Html position={[-1, 3.2, 0]}>
          <Sticky>
            <button
              onClick={() => {
                setAnimateJump(!animateJump);
                onSelect();
              }}
              className="MuiButtonBase-root css-1l135z1"
              type="button"
            >
              <span className="MuiTypography-root MuiTypography-caption css-1fci3kk">
                {animateJump ? "ENOUGH" : "JUMP!"}
              </span>
              <span className="MuiTouchRipple-root css-w0pj6f"></span>
            </button>
          </Sticky>
        </Html>
      )}
      <primitive
        ref={allStarRef}
        object={nodes.Scene}
        scale={0.85}
        castShadow={true}
        receiveShadow={true}
      />
    </group>
  );
};

// useGLTF.preload(modelPath);
